<template>
  <div class="font-montserrat d-flex flex-column choose-role-content">
    <!-- form -->
    <validation-observer
      #default="{ invalid }"
    >
      <b-form @submit.prevent="onSubmit">
        <validation-provider
          #default="{ errors }"
          name="email"
          vid="email"
          rules="required|email"
        >
          <b-form-group
            class="custom-input-group mrg-bottom-16"
            :class="
              isDirty.email ? errors.length > 0 ? 'invalid-input-group' : 'valid-input-group' : ''
            "
          >
            <b-form-input
              v-model="signupDetails.email"
              class="custom-input"
              type="email"
              placeholder="Email"
            />
            <span
              class="custom-input-placeholder text-capitalize font-montserrat"
              data-content="Email"
            >
              Email
            </span>
            <validation-icon-with-tooltip
              id="email-validation"
              class="kp-validation-icon"
              :show="isDirty.email"
              :errors="errors"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="password"
          vid="password"
          rules="required|min:8"
        >
          <b-form-group
            class="custom-input-group mrg-bottom-16"
            :class="
              isDirty.password ? errors.length > 0 ? 'invalid-input-group' : 'valid-input-group' : ''
            "
          >
            <b-form-input
              ref="password"
              v-model="signupDetails.password"
              class="custom-input"
              :type="passwordFieldType"
              placeholder="Password"
              autocomplete="off"
            />
            <feather-icon
              v-if="isDirty.password"
              :icon="passwordToggleIcon"
              class="kp-eye-icon"
              @click="togglePasswordVisibility"
            />
            <span
              class="custom-input-placeholder text-capitalize font-montserrat"
              data-content="Password"
            >
              Password
            </span>
            <validation-icon-with-tooltip
              id="password-validation"
              class="kp-validation-icon"
              :show="isDirty.password"
              :errors="errors"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="Re-enter Password"
          vid="re-enter-password"
          rules="required|min:8|confirmed:password"
        >
          <b-form-group
            class="custom-input-group mrg-bottom-16"
            :class="
              isDirty.password2 ? errors.length > 0
                ? 'invalid-input-group'
                : 'valid-input-group' : ''
            "
          >
            <b-form-input
              v-model="signupDetails.password2"
              class="custom-input"
              :type="passwordFieldType"
              placeholder="Re-enter Password"
              autocomplete="off"
            />
            <span
              class="custom-input-placeholder text-capitalize font-montserrat"
              data-content="Re-enter Password"
            >
              Re-enter Password
            </span>
            <feather-icon
              v-if="isDirty.password2"
              :icon="passwordToggleIcon"
              class="kp-eye-icon"
              @click="togglePasswordVisibility"
            />
            <validation-icon-with-tooltip
              id="re-enter-password"
              class="kp-validation-icon"
              :show="isDirty.password2"
              :errors="errors"
            />
          </b-form-group>
        </validation-provider>
        <policy-and-terms
          @on-accept-policy="handleAcceptPolicy"
          @on-privacy-link-click="handlePrivacyLinkClick"
          @on-terms-link-click="handleTermsLinkClick"
        />
        <b-button
          type="submit"
          class="font-inter custom-btn-submit mrg-bottom-16"
          variant="info"
          block
          :disabled="invalid || !isAcceptedPolicy || loading"
        >
          <span> Create Account </span>
          <feather-icon
            v-if="loading"
            size="18"
            icon="LoaderIcon"
          />
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import {
 BButton, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, confirmed } from '@validations'
import ValidationIconWithTooltip from '@/@core/components/icon-with-tooltip-error/ValidationIconWithTooltip.vue'
import PolicyAndTerms from '@/@core/app-related-components/PolicyAndTerms.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapState } from 'vuex'
import { REGISTER } from '@/store/modules/auth.module'
import constants from '@/constants'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import { formatObject } from '@/@core/utils/utils'
import analytics from '@/@core/utils/analytics'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { SET_IS_ADMIN_SIGNUP } from '@/store/modules/signup.module'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { capitalize } from 'lodash'

const { USER_STATUS } = c

// Custom error message for password confirmation
extend('confirmed', {
    ...confirmed,
    message: 'Please make sure this password match with the password you entered above.',
})

export default {
  name: 'SetPassword',
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    ValidationIconWithTooltip,
    PolicyAndTerms,
    FeatherIcon,
  },
  mixins: [togglePasswordVisibility],
  props: {
    routeQueryParams: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      emailEdited: false,
      isAcceptedPolicy: false,
      // For Validation
      isDirty: {
        email: false,
        password: false,
        password2: false,
      },
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    ...mapState({
      signupDetails: state => state.signup.signupDetails,
    }),
  },
  watch: {
    signupDetails: {
      handler(val) {
        if (val.email) {
          this.isDirty.email = true
        }
        if (val.password) {
          this.isDirty.password = true
        }
        if (val.password2) {
          this.isDirty.password2 = true
        }
        analytics.track(constants.TRACKS.ACTIONS.SIGN_UP.USER_ENTERS_CREDENTIALS, {
          email_domain: val.email ? val.email.split('@')[1] : '',
          password_length: val.password ? val.password.length : 0,
          timestamp: Date.now(),
        })
      },
      deep: true,
    },
  },
  created() {
    if (this.routeQueryParams.isAdmin === 'true') {
      this.signupDetails.status = USER_STATUS.APPROVED
      this.$store.commit(SET_IS_ADMIN_SIGNUP)
    }
    if (this.routeQueryParams.email) {
      this.signupDetails.email = this.routeQueryParams.email
    }
  },
  methods: {
    handleAcceptPolicy(val) {
      this.isAcceptedPolicy = val

      analytics.track(constants.TRACKS.ACTIONS.SIGN_UP.USER_CLICKS_AGREE_TO_TERMS, {
        agreement: 'Terms and conditions',
        timestamp: Date.now(),
      })
    },
    handlePrivacyLinkClick() {
      analytics.track(constants.TRACKS.ACTIONS.SIGN_UP.USER_CLICKS_PRIVACY_LINK, {
        link_name: 'Privacy Policy',
        timestamp: Date.now(),
      })
    },
    handleTermsLinkClick() {
      analytics.track(constants.TRACKS.ACTIONS.SIGN_UP.USER_CLICKS_TERMS_LINK, {
        link_name: 'Terms and conditions',
        timestamp: Date.now(),
      })
    },
    onSubmit() {
      this.loading = true
      const payload = {
        ...this.signupDetails,
      }
      analytics.track(constants.TRACKS.ACTIONS.SIGN_UP.USER_CLICKS_CREATE_ACCOUNT_BUTTON, {
        user_type: capitalize(this.signupDetails.role),
        timestamp: Date.now(),
      })
      this.$store
        .dispatch(REGISTER, payload)
        .then(() => {
          this.loading = false
          this.$router.push({
            path: '/login',
          })
          apiToastSuccess(
            "You have successfully registered! Let's login!",
            'CoffeeIcon',
            )
          analytics.track(
            constants.TRACKS.ACTIONS.REGISTER,
            formatObject(payload, { password: 1, password2: 1 }),
          )
        })
        .catch(message => {
          this.loading = false
          apiToastWarning(message, 'TimesIcon')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/pages/signup/kp-signup.scss';
</style>
