var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-montserrat d-flex flex-column choose-role-content"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('validation-provider',{attrs:{"name":"email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"custom-input-group mrg-bottom-16",class:_vm.isDirty.email ? errors.length > 0 ? 'invalid-input-group' : 'valid-input-group' : ''},[_c('b-form-input',{staticClass:"custom-input",attrs:{"type":"email","placeholder":"Email"},model:{value:(_vm.signupDetails.email),callback:function ($$v) {_vm.$set(_vm.signupDetails, "email", $$v)},expression:"signupDetails.email"}}),_c('span',{staticClass:"custom-input-placeholder text-capitalize font-montserrat",attrs:{"data-content":"Email"}},[_vm._v(" Email ")]),_c('validation-icon-with-tooltip',{staticClass:"kp-validation-icon",attrs:{"id":"email-validation","show":_vm.isDirty.email,"errors":errors}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","vid":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"custom-input-group mrg-bottom-16",class:_vm.isDirty.password ? errors.length > 0 ? 'invalid-input-group' : 'valid-input-group' : ''},[_c('b-form-input',{ref:"password",staticClass:"custom-input",attrs:{"type":_vm.passwordFieldType,"placeholder":"Password","autocomplete":"off"},model:{value:(_vm.signupDetails.password),callback:function ($$v) {_vm.$set(_vm.signupDetails, "password", $$v)},expression:"signupDetails.password"}}),(_vm.isDirty.password)?_c('feather-icon',{staticClass:"kp-eye-icon",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}}):_vm._e(),_c('span',{staticClass:"custom-input-placeholder text-capitalize font-montserrat",attrs:{"data-content":"Password"}},[_vm._v(" Password ")]),_c('validation-icon-with-tooltip',{staticClass:"kp-validation-icon",attrs:{"id":"password-validation","show":_vm.isDirty.password,"errors":errors}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Re-enter Password","vid":"re-enter-password","rules":"required|min:8|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"custom-input-group mrg-bottom-16",class:_vm.isDirty.password2 ? errors.length > 0
              ? 'invalid-input-group'
              : 'valid-input-group' : ''},[_c('b-form-input',{staticClass:"custom-input",attrs:{"type":_vm.passwordFieldType,"placeholder":"Re-enter Password","autocomplete":"off"},model:{value:(_vm.signupDetails.password2),callback:function ($$v) {_vm.$set(_vm.signupDetails, "password2", $$v)},expression:"signupDetails.password2"}}),_c('span',{staticClass:"custom-input-placeholder text-capitalize font-montserrat",attrs:{"data-content":"Re-enter Password"}},[_vm._v(" Re-enter Password ")]),(_vm.isDirty.password2)?_c('feather-icon',{staticClass:"kp-eye-icon",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}}):_vm._e(),_c('validation-icon-with-tooltip',{staticClass:"kp-validation-icon",attrs:{"id":"re-enter-password","show":_vm.isDirty.password2,"errors":errors}})],1)]}}],null,true)}),_c('policy-and-terms',{on:{"on-accept-policy":_vm.handleAcceptPolicy,"on-privacy-link-click":_vm.handlePrivacyLinkClick,"on-terms-link-click":_vm.handleTermsLinkClick}}),_c('b-button',{staticClass:"font-inter custom-btn-submit mrg-bottom-16",attrs:{"type":"submit","variant":"info","block":"","disabled":invalid || !_vm.isAcceptedPolicy || _vm.loading}},[_c('span',[_vm._v(" Create Account ")]),(_vm.loading)?_c('feather-icon',{attrs:{"size":"18","icon":"LoaderIcon"}}):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }